<template>
  <div class="">
    <section id="main" class="main-index">
      <div class="main-index-video-wrap">
        <video class="mainVideo desktop" autoplay loop="" muted="" poster="" playsinline>
          <source :src="anipangClub_pc_webm" type="video/webm">
          <source :src="anipangClub_pc" type="video/mp4">
        </video>
        <video class="mainVideo mobile" autoplay loop="" muted="" poster="" playsinline>
          <source :src="anipangClub_mobile_webm" type="video/webm">
          <source :src="anipangClub_mobile" type="video/mp4">
        </video>
      </div>
    </section>

    <div id="main-panel">

      <div id="ac-introduction"
           class="ac-introduction"
           >
        <div class="text-center">
          <h1 class="title">
            <img :src="image_head_title"
                 :alt="language.main_introduction_title">
          </h1>
          <h4 class="description text-mod">
            {{language.main_introduction_01}}<br>
            <br>
            {{language.main_introduction_02}}<br>
            {{language.main_introduction_03}}<br>
            {{language.main_introduction_04}}<br>
            {{language.main_introduction_05}}<br>
            <br>
            {{language.main_introduction_06}}<br>
            {{language.main_introduction_07}}<br>
            <br>
            {{language.main_introduction_08}}<br>
            {{language.main_introduction_09}}<br>
          </h4>
          <!-- <br><br><br><br><br> -->
          <div class="banner-mod" >
              <router-link :to="base.isDomestic ? `/games/pre-register` : `/games/pre-register`"
                           class="pre-order-game-intro-links-mod">

<!--                /games/pre-register-->
                <img v-if="locale === 'ko'" :src="lineup_button.ko" alt="라인업보러기기버튼"/>
                <img v-else :src="lineup_button.en" alt="라인업보러기기버튼"/>
              </router-link>
          </div>

          <div class="steps-wrap md-layout">
            <div class="md-layout-item">
                <div class="plan"><span class="coloured-img plan-2"><img :src="lineup_banner.match" alt="애니팡매치로고"></span></div>
            </div>
            <div class="md-layout-item">
                <div class="plan"><span class="coloured-img plan-3"><img :src=lineup_banner.blast alt="애니팡블라스트로고"></span></div>
            </div>
            <div class="md-layout-item">
                <div class="plan"><span class="coloured-img plan-4"><img :src="lineup_banner.coins" alt="애니팡코인즈로고"></span></div>
            </div>
          </div>
        </div>
        <video class="mainVideo desktop" autoplay loop="" muted="" poster="" playsinline>
          <source :src="introduction_bg_video" type="video/mp4">
        </video>
      </div>

    </div>

    <ACBubbleLink/>

    <!-- <section id="main2" class="p2e-games">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-for="(item, index) in banners"
                      class="game-banner-wrap"
                      role="tab"
                      :key="index">
          <div class="game-banner-item-wrap" :id="index">
            <div class="banner-mod" >
              <router-link :to="base.isDomestic ? `/games/pre-register` : `/games/${item.name}`"
                           class="pre-order-game-intro-links"
                           :style="`background-color: #${item.bg_color};`">
                {{language.games_match_intro_button}}
              </router-link>
              <span class="game-banner game-banner-img-wrap">
                <span>
                  <img :src="item.img" />
                </span>
              </span>
              <span class="game-banner game-banner-bg-wrap">
                <span>
                  <img :src="item.bg" />
                </span>
              </span>
            </div>
          </div>
        </swiper-slide>
        <div id="pagination" class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div id="pagination" class="swiper-button-next swiper-button-white" slot="button-next"></div>
      </swiper>
    </section> -->



  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import Mixins from "@/plugins/basicMixins";
  import {
    ACBubbleLink,
  } from "@/components";
  // import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  // import 'swiper/css/swiper.css'

  export default {
    mixins: [Mixins.HeaderImage],
    bodyClass: "ac-index",
    components: {
      ACBubbleLink,
      // Swiper,
      // SwiperSlide,
    },
    computed: {
      ...mapGetters([
        "base",
        "locale",
        'language',
      ]),
    },
    methods: {
      popupImage(image) {
        return {
          backgroundImage: `url(${image})`,
        };
      }
    },
    data() {
      return {
        image_head_title: require("@/assets/img/pages/index/introduction.png"),
        introduction_bg_video: require("@/assets/img/pages/index/03_schedule_BG.mp4"),

        anipangClub_pc: require("@/assets/img/pages/index/AnipangClub_pc_BG.mp4"),
        anipangClub_pc_webm: require("@/assets/img/pages/index/AnipangClub_BG_1920x1280.webm"),
        anipangClub_mobile: require("@/assets/img/pages/index/AnipangClub_mobile_BG.mp4"),
        anipangClub_mobile_webm: require("@/assets/img/pages/index/AnipangClub_BG_720x1280.webm"),

        popup: {
          popup_btn_play_nft: require("@/assets/img/pages/index/popup_btn_play_nft.png"),
          popup_btn_supporting: require("@/assets/img/pages/index/popup_btn_supporting.png"),
          switch_modal_img: require("@/assets/img/pages/index/popup_text.png"),
          switch_modal_bg: require("@/assets/img/pages/index/popup_bg.png"),
        },

        banners: [
          {
            name: 'match',
            img: require("@/assets/img/pages/index/03_character.png"),
            bg: require("@/assets/img/pages/index/03_bg.png"),
            text_color: '3d9731',
            bg_color: '2b8f1d',
          },
          {
            name: 'blast',
            img: require("@/assets/img/pages/index/01_character.png"),
            bg: require("@/assets/img/pages/index/01_bg.png"),
            text_color: '59b5ff',
            bg_color: '007ee2',
          },
          {
            name: 'coins',
            img: require("@/assets/img/pages/index/02_character.png"),
            bg: require("@/assets/img/pages/index/02_bg.png"),
            text_color: '7f7dbe',
            bg_color: '2621c3',
          },
        ],

        swiperOption: {
          spaceBetween: 30,
          slidesPerView: 1,
          loop: true,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
        },
        lineup_button: {
          ko: require("@/assets/img/pages/index/btn_lineup_ko.png"),
          en: require("@/assets/img/pages/index/btn_lineup_en.png")
        },
        lineup_banner: {
          match: require("@/assets/img/pages/index/logo_01.png"),
          blast: require("@/assets/img/pages/index/logo_02.png"),
          coins: require("@/assets/img/pages/index/logo_03.png")
        }

      };
    },
  };
</script>
<style lang="scss">

.ac-introduction h1.title{
    margin-bottom: -25px;
}

@media (max-width: 480px) {
	.ac-introduction .steps-wrap.md-layout {
		height: 460px;
	}

	.ac-introduction .steps-wrap .md-layout-item  {
		display: block;
		margin: 0 auto;
		margin-bottom: 24px;
	}

  .l-minting.aos-init.aos-animate {
    width: 80px;
  }

  .l-minting.aos-init.aos-animate a.item{
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 967px) {
  .ac-index .index .swiper-slide {
    height: 25rem;
  }
}

@media screen and (max-width: 576px) {
  .ac-index .switch_modal_content {
    padding: 0 0 90% !important;
  }
}

.ac-index {
  .main-index {
    height: 100vh;

    .main-index-video-wrap {
      height: 100vh;
      position: relative;
    }
  }

  .ac-introduction {
    position: relative;
    //background-size: cover;

    .mainVideo {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      z-index: 0;
    }

    .page-header .container {
      width: 100%;
      max-width: 880px;
    }

    .text-center {
      z-index: 1;
      position: relative;
      overflow-x: hidden;
    }

    .header-filter:before,
    .header-filter:after {
      display: none;
    }

    .title {
      font-size: 10em;
      color: #fff;
      letter-spacing: 14px;
      font-weight: 800;
      margin: 0;
      margin-bottom: -25px;
    }

    .sub-title {
      margin: 0 auto;
      max-width: 400px;
      font-weight: 600;
      font-size: 1.5rem;
    }

    .description {
      line-height: 1.7em;
      padding: 15px;
      font-size: 14px;
      margin: 0 auto;
      max-width: 400px;
      font-weight: 600;
      color: white;
      word-break: keep-all;
    }
  }

  .p2e-games {

    .swiper-container {
      &:before,
      &:after {
        content: none;
      }
    }

    .game-banner-item-wrap {
      display: flex;
      flex-direction: column;
      height: 36rem;
      justify-content: center;
      max-height: 500px;

      .game-banner-item {
        bottom: 0;
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;


        .pre-order-game-intro-links {
          position: absolute;
          left: 50%;
          top:80%;
          width: 200px;
          margin-left: -100px;
          z-index: 2;
          margin-bottom: 28px;
          padding: 12px 0;
          display: inline-block;
          outline: none;
          cursor: pointer;
          text-align: center;
          text-decoration: none;
          font-family: 'yg-jalnan';
          //background-color: #2b8f1d !important;
          color: white;
          font-size: 19px;
          text-shadow: 0 1px 1px rgba(0,0,0,.3);
          -webkit-border-radius: 5rem;
          -moz-border-radius: 5rem;
          border-radius: 5rem;
          border: 5px solid #fff;
          -webkit-box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
          box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);
          &:hover {
            color: white !important;
          }
        }

        .game-banner {
          &.game-banner-img-wrap {
            display: block;
            position: relative;
            height: 100%;
            z-index: 1;

            span {
              max-width: 1000px;
              margin: 0 auto;
            }
          }
          &.game-banner-bg-wrap {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 0;
            display: block;
            width: 100%;
          }
          span {
            display: block;
            height:100%;
            img {
              width:100%;
              height:100%;
              object-fit:cover;
            }
          }
        }
      }
    }
  }

  .modal-switch {
    will-change: transform;
    position: fixed;
    z-index: 1031;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: inherit;

    &.show {
      background-color: rgba(0, 0, 0, 0.6);
    }

    .modal-wrapper {
      position: absolute;
      z-index: 10;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 90%;
      margin: 0 auto;

      .modal-container {
        max-width: 100%;
        overflow: auto;
        margin: 0 auto;
        position: relative;
        background-color: #fff;
        border: none;

        .modal-header {
          display: none;
        }

        .modal-body {
          padding: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          .switch_modal_wrap {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            .switch_modal_img {
              padding: 30px 0 20px;
            }
            .switch_modal_content {
              padding: 0 0 35%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.banner-mod {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 8px;
}

.pre-order-game-intro-links {
    width: 200px;
    z-index: 2;
    margin-top: 64px;
    padding: 12px 0;
    display: inline-block;
    left: 50%;
    outline: none;
    cursor: pointer;
    text-align: center; text-decoration: none;
    font-family: yg-jalnan; font-size: 19px;
    color: #fff !important;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
    border-radius: 5rem; border: 5px solid #fff;
    background-color: rgb(43, 143, 29);
}

.pre-order-game-intro-links-mod {
    left: 50%;
    z-index: 2;
    display: block;
    cursor: pointer;
    width: 230px; height: 90px;
    overflow: hidden;
    cursor: pointer;
}

.pre-order-game-intro-links-mod img {
    object-fit: cover;
    width: inherit; height: inherit;
    object-position: top left;
}

.pre-order-game-intro-links-mod.logo {
    margin-top: 80px;
    width: 672px; height: 126px;
    cursor: default;
}

.steps-wrap .md-layout-item {
    height: 210px;
    padding-right: 0; padding-left: 0
}

.steps-wrap .md-layout-item.md-small-size-50 .plan {
    width: 70%; height: 70%;
    position: relative
}

.steps-wrap .md-layout-item .plan {
    width: 100%;
    height: 100%;
    position: relative;
}

.steps-wrap .md-layout-item .coloured-img {
    position: absolute;
    display: block;
    width: 100%;
}
.description.text-mod {
    max-width:800px !important;
    color: #CFCEF4 !important;
    font-size: 1rem !important;
}

.description.text-mod > strong {
    font-weight: 800;
    color: #ffffff !important;
}

.ac-introduction .md-layout {
    display: block;
    height: 300px;
    margin-top: 56px;
}

.steps-wrap .md-layout-item  {
    display: inline-block;
    width: 224px; height: 126px;
}

.steps-wrap .md-layout-item .plan-2 {
    left: 0; top: 20px;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s
}

.steps-wrap .md-layout-item .plan-3 {
    left: 0; top: 0;
    -webkit-animation-name: Floatingy1;
    -moz-animation-name: Floatingy1;
    -webkit-animation-duration: 10s;
    -moz-animation-duration: 10s
}

.steps-wrap .md-layout-item .plan-4 {
    left: 0; top: 10px;
    -webkit-animation-name: Floatingy;
    -moz-animation-name: Floatingy;
    -webkit-animation-duration: 6s;
    -moz-animation-duration: 6s
}

.coloured-img {
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out
}

.l-minting.aos-init.aos-animate {
    width: 120px;
}

.l-minting.aos-init.aos-animate a.item{
    width: 140px;
    height: 140px;
}

.l-minting.aos-init.aos-animate a.item .img{
    width: 140px;
    height: 140px;
}

@media (max-width: 992px) {
    .l-minting.aos-init.aos-animate a.item {
        width: 80px;
        height: 80px;
    }
    .l-minting.aos-init.aos-animate a.item .img {
        width: 80px;
        height: 80px;
    }
    .l-minting.aos-init.aos-animate {
        width: 80px;
        height: 80px;
    }
}

</style>
